import React from "react";
import styles from "./styles.module.css"
import title1 from "./page_titles/sobre1.svg"
import title2 from "./page_titles/sobre2.svg"
import GoToTop from "../../0components/GoToTop/GoToTop";
import image1 from "../../2assets/ap_leblon_copa/ap_leblon_copa18.jpg"
import image2 from "../../2assets/melanina/melanina7.jpg"

function Home() {

    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

            <div className={`${styles.itemGaleria} ${styles.h3} ${styles.w8} ${styles.iw3} ${styles.ih3}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h3} ${styles.w4} ${styles.textBlock} ${styles.iw3}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            Sempre estive, por influências em minha família, envolta em referências de artes, arquitetura e moda. Desde de criança, essas são áreas que me atraem e fascinam, o que me fez desenvolver um lado criativo e um desejo de trabalhar com o belo e funcional.
                        </p> <br></br>
                        <p>
                            Desde 2017 crio novas formas de vivenciar espaços. Muito além das estéticas arquitetônicas, me identifico com o fazer arquitetura: pensar em novas maneiras de contemplar a vida.
                        </p><br></br>
                        <p>
                            Nunca me identifiquei com a ideia de um escritório, restrito aos seus ideais de criação.  Essa forma de pensar deu origem a esse Studio, um lugar de troca entre colaboradores e clientes, com liberdade criativa em todas as escalas, de objetos à paisagens.
                        </p> <br></br>
                        <p>
                            A arquitetura tem o poder de mudar a vida de quem a vive. Por isso, no estúdio estamos ligados pela vontade de levar a arquitetura para dentro da vida de cada cliente, criando assim espaços de pertencimento.
                        </p> <br></br>
                      
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h3} ${styles.w8} ${styles.iw3} ${styles.ih3}`}>
                    <img className={styles.imgGaleria} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.textBlock} ${styles.iw3}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            Soluções comerciais e residenciais, com três possibilidades de atendimento de acordo com sua demanda!
                        </p> <br></br>
                        <p>
                            <span>Projeto Arquitetônico</span>. Solução completa para a construção ou reforma arquitetônica, para quem quer mudar a área construída ou começar uma obra do zero. Com esse projeto estudamos a volumetria do seu imóvel, pensando nas fachadas e sua relação com o entorno. Entrega técnica com projetos de aprovação e execução.
                        </p> <br></br>
                        <p>
                            <span>Projeto de Interiores</span>. Projeto completo de interiores, das paredes à decoração. Em escala menor, estudamos as partes internas do seu imóvel, pensando do piso à marcenaria como um todo. Você pode contratar esse serviço para um ambiente ou para o imóvel completo. Entrega técnica com projetos de execução.
                        </p> <br></br>
                        <p>
                            <span>Consultoria</span>. Soluções fáceis de serem aplicadas em espaços específicos. Para quem quer mudar muito, mudando pouco. Podemos desenhar um mobiliário, alterar a pintura do ambiente, ou até mesmo escolher novos móveis e decoração. Entrega com desenhos esquemáticos e consultoria de visitas em lojas.
                        </p> <br></br>
                        <p>
                            <span>contato:</span>. alexia@studiomaggioni.com.br
                        </p> <br></br>
                    
                    </div>
                </div>
            </div>
            <GoToTop></GoToTop>
        </div>
    )
}

export default Home;