import './App.css';
import Header from './0components/Header';
import { Outlet } from 'react-router-dom';
import Footer from "./0components/Footer"

function App() {
  return (
    <div>
      <Header></Header>
      <div className='container_parent'>
        <Outlet />
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
