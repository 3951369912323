import React from "react";
import style from "./styles.module.css"
import { Link } from "react-router-dom"
import leblonCopaTitle from "../../3projects/leblon_copa/leblon_copa_titles/leblon_copa1.svg"
import leblonCopaImage from "../../2assets/ap_leblon_copa/ap_leblon_copa1.jpg"
import melaninaTitle from "../../3projects/melanina/melanina_titles/melanina1.svg"
import melaninaImage from "../../2assets/melanina/melanina5.jpg"
import cortelaTitle from "../../3projects/cortela/cortela_titles/cortela1.svg"
import cortelaImage from "../../2assets/cortela/cortela4.jpg"
import leblonBarraImage from "../../2assets/ap_leblon_barra/ap_leblon_barra8.jpg"
import leblonBarraTitle from "../../3projects/leblon_barra/leblon_barra_titles/leblon_barra1.svg"
import azulTitle from "../../3projects/azul/azul_titles/azul1.svg"
import azulImage from "../../2assets/azul/azul2.jpg"
import geralTitle from "../../3projects/geral/geral_titles/geral1.svg"
import geralImage from "../../2assets/geral/geral19.jpg"
import granTitle from "../../3projects/gran/gran_titles/gran1.svg"
import granImage from "../../2assets/gran/gran4.jpg"
import pedrasImage from "../../2assets/casa_pedras/pedras2.jpg"
import pedrasTitle from "../../3projects/casa_pedras/pedras_titles/pedras1.svg"
import pedrasInteriorImage from "../../2assets/pedras_interior/pedras_interior3.jpg"
import pedrasInteriorTitle from "../../3projects/pedras_interior/interior_titles/pedras_interior1.svg"
import unicoTitle from "../../3projects/unico/unico_titles/unico1.svg"
import unicoImage from "../../2assets/unico/unico18.jpg"
import jardimImage from "../../2assets/jardim_bueno/jardim3.jpg"
import jardimTitle from "../../3projects/jardim_bueno/jardim_titles/jardim1.svg"
import lazerTitle from "../../3projects/lazer/lazer_titles/lazer1.svg"
import lazerImage from "../../2assets/lazer/lazer15.jpg"

import GoToTop from "../../0components/GoToTop/GoToTop";


function Projetos() {

    return (
        <div className={style.megaContainer}>
            <div className={style.container}>

                <div className={style.itemGaleria}>
                    <Link to="/melanina">
                        <img className={style.imgGaleria} src={melaninaImage} alt="imagem melanina"></img>
                        <div>
                            <img className={style.textBox} src={melaninaTitle} alt="titulo melanina"></img>
                        </div>
                    </Link>
                </div>

                <div className={style.itemGaleria}>
                    <Link to="/leblon_copa">
                        <img className={style.imgGaleria} src={leblonCopaImage} alt="imagem leblon copa"></img>
                        <div className={style.reverse}>
                            <img className={style.textBox} src={leblonCopaTitle} alt="titulo leblon copa"></img>
                        </div>
                    </Link>
                </div>

                <div className={style.itemGaleria}>
                    <Link to="/cortela">
                        <img className={style.imgGaleria} src={cortelaImage} alt="imagem cortela"></img>
                        <div>
                            <img className={style.textBox} src={cortelaTitle} alt="titulo cortela"></img>
                        </div>
                    </Link>
                </div>

                <div className={style.itemGaleria}>
                    <Link to="/leblon_barra">
                        <img className={style.imgGaleria} src={leblonBarraImage} alt="imagem leblon barra"></img>
                        <div className={style.reverse}>
                            <img className={style.textBox} src={leblonBarraTitle} alt="titulo leblon barra"></img>
                        </div>
                    </Link>
                </div>

                <div className={style.itemGaleria}>
                    <Link to="/gran_poeme">
                        <img className={style.imgGaleria} src={granImage} alt="imagem cortela"></img>
                        <div>
                            <img className={style.textBox} src={granTitle} alt="titulo ap azul"></img>
                        </div>
                    </Link>
                </div>

                <div className={style.itemGaleria}>
                    <Link to="/geral">
                        <img className={style.imgGaleria} src={geralImage} alt="imagem geral sports bar"></img>
                        <div className={style.reverse}>
                            <img className={style.textBox} src={geralTitle} alt="titulo geral sports bar"></img>
                        </div>
                    </Link>
                </div>

                <div className={style.itemGaleria}>
                    <Link to="/casa_pedras">
                        <img className={style.imgGaleria} src={pedrasImage} alt="imagem casa das pedras"></img>
                        <div>
                            <img className={style.textBox} src={pedrasTitle} alt="titulo casa das pedras"></img>
                        </div>
                    </Link>
                </div>

                <div className={style.itemGaleria}>
                    <Link to="/pedras_interior">
                        <img className={style.imgGaleria} src={pedrasInteriorImage} alt="imagem casa das pedras interior"></img>
                        <div className={style.reverse}>
                            <img className={style.textBox} src={pedrasInteriorTitle} alt="titulo casa das pedras interior"></img>
                        </div>
                    </Link>
                </div>

                <div className={style.itemGaleria}>
                    <Link to="/azul">
                        <img className={style.imgGaleria} src={azulImage} alt="imagem ap azul"></img>
                        <div>
                            <img className={style.textBox} src={azulTitle} alt="titulo ap azul"></img>
                        </div>
                    </Link>
                </div>

                <div className={style.itemGaleria}>
                    <Link to="/unico">
                        <img className={style.imgGaleria} src={unicoImage} alt="imagem apartamento unico"></img>
                        <div className={style.reverse}>
                            <img className={style.textBox} src={unicoTitle} alt="titulo apartamento unico"></img>
                        </div>
                    </Link>
                </div>

                <div className={style.itemGaleria}>
                    <Link to="/jardim">
                        <img className={style.imgGaleria} src={jardimImage} alt="imagem jardim bueno"></img>
                        <div>
                            <img className={style.textBox} src={jardimTitle} alt="titulo jardim bueno" ></img>
                        </div>
                    </Link>
                </div>

                <div className={style.itemGaleria}>
                    <Link to="/lazer">
                        <img className={style.imgGaleria} src={lazerImage} alt="imagem espaço de lazer"></img>
                        <div className={style.reverse}>
                            <img className={style.textBox} src={lazerTitle} alt="titulo espaço de lazer"></img>
                        </div>
                    </Link>
                </div>

            </div>
             <GoToTop />
        </div>
    )
}

export default Projetos;









// <div className={styles.container}>

// <Link to="/melanina"><div className={`${styles.box} ${styles.h2} ${styles.w2}`}>
//     <div>
//         <img src={melaninaImage} alt="foto melanina"></img>
//     </div>
//     <div>
//         <img src={melaninaTitle} alt="projeto de arquitetura melanina bar"></img>
//     </div>
// </div></Link>


// <Link to="/leblon_copa"><div className={styles.box}>
//     <div>
//         <img src={leblonCopaImage} alt="foto melanina"></img>
//     </div>
//     <div>
//         <img src={leblonCopaTitle} alt="projeto de arquitetura leblon copa"></img>
//     </div>
// </div> </Link>

// <GoToTop />
// </div>