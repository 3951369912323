import title1 from "../lazer/lazer_titles/lazer1.svg"
import title2 from "../lazer/lazer_titles/lazer2.svg"
import styles from "./styles.module.css"
import GoToTop from "../../0components/GoToTop/GoToTop"
import image1 from "../../2assets/lazer/lazer1.jpg"
import image2 from "../../2assets/lazer/lazer2.jpg"
import image3 from "../../2assets/lazer/lazer3.jpg"
import image4 from "../../2assets/lazer/lazer4.jpg"
import image5 from "../../2assets/lazer/lazer5.jpg"
import image6 from "../../2assets/lazer/lazer6.jpg"
import image7 from "../../2assets/lazer/lazer7.jpg"
import image8 from "../../2assets/lazer/lazer8.jpg"
import image9 from "../../2assets/lazer/lazer9.jpg"
import image10 from "../../2assets/lazer/lazer10.jpg"
import image11 from "../../2assets/lazer/lazer11.jpg"
import image12 from "../../2assets/lazer/lazer12.jpg"
import image13 from "../../2assets/lazer/lazer13.jpg"
import image14 from "../../2assets/lazer/lazer14.jpg"
import image15 from "../../2assets/lazer/lazer15.jpg"
import image16 from "../../2assets/lazer/lazer16.jpg"
import image17 from "../../2assets/lazer/lazer17.jpg"
import image18 from "../../2assets/lazer/lazer18.jpg"
import image19 from "../../2assets/lazer/lazer19.jpg"
import image20 from "../../2assets/lazer/lazer20.jpg"



function Index() {


    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image3} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            Em ano de copa do mundo, os clientes nos procuraram para criar uma extensão em sua casa, que seria uma área gourmet, se não fosse uma área de lazer completa, com tudo que eles têm direito!
                        </p><br></br>
                        <p>
                            O primeiro desafio deste projeto foi criar uma volumetria que não destoasse do volume existente da casa. Para isso, criamos um volume linear que mimetiza os materiais e alturas existentes, criando uma conexão entre os espaços, para que o casal pudesse aproveitar a casa da melhor maneira possível. Uma forma de integrar esse novo volume com seu entorno foi através das aberturas generosas que criamos no espaço, que emoldura a natureza do jardim e seu entorno, trazendo iluminação e ventilação natural para o ambiente.
                        </p><br></br>
                        <p><span>
                            112m².<br></br>
                            Setembro de 2022.<br></br>
                            Brasília, DF.<br></br>
                            </span>
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image1} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image4} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.ih3} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            O programa do projeto é extenso e inclui uma cozinha para preparo de alimentos, espaço de jantar, espaço de estar e mesa de jogos com tv. Por pedido do casal, todas essas atividades deveriam estar integradas para que pudessem receber os amigos e familiares com conforto.
                        </p> <br></br>
                        <p>
                            Todos esses usos do espaço estão integrados e abrigados por um forro de madeira, que aquece o ambiente com auxílio da iluminação que incide diretamente sobre ele e distribui os pontos de luz para os outros ambientes.
                        </p><br></br>
                        <p>
                            A cozinha, coração do projeto, foi o ponto de partida. Criamos uma volumetria que destaca e direciona os olhos para a área de preparo que é acompanhada por uma bancada e mesa de jantar.
                        </p><br></br>
                        <p>
                            Para esse projeto, utilizamos materiais naturais como a pedra portuguesa, cimento queimado e madeira, queridinhos do Studio.
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}   `}>
                    <img className={styles.imgGaleria} src={image5} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image6} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image7} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}  `}>
                    <img className={styles.imgGaleria} src={image8} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image9} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image10} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image11} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image12} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image13} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image14} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image16} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image15} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image17} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image18} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image19} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw4} ${styles.ih2}`}>
                    <img className={styles.imgGaleria} src={image20} alt=""></img>
                </div>

            </div>
            <GoToTop />
        </div>
    )
}

export default Index;



