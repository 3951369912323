import title1 from "../jardim_bueno/jardim_titles/jardim1.svg"
import title2 from "../jardim_bueno/jardim_titles/jardim2.svg"
import styles from "./styles.module.css"
import GoToTop from "../../0components/GoToTop/GoToTop"
import image1 from "../../2assets/jardim_bueno/jardim1.jpg"
import image2 from "../../2assets/jardim_bueno/jardim2.jpg"
import image3 from "../../2assets/jardim_bueno/jardim3.jpg"
import image4 from "../../2assets/jardim_bueno/jardim4.jpg"
import image5 from "../../2assets/jardim_bueno/jardim5.jpg"
import image6 from "../../2assets/jardim_bueno/jardim6.jpg"
import image7 from "../../2assets/jardim_bueno/jardim7.jpg"
import image8 from "../../2assets/jardim_bueno/jardim8.jpg"
import image9 from "../../2assets/jardim_bueno/jardim9.jpg"
import image10 from "../../2assets/jardim_bueno/jardim10.jpg"
import image11 from "../../2assets/jardim_bueno/jardim11.jpg"
import image12 from "../../2assets/jardim_bueno/jardim12.jpg"
import image13 from "../../2assets/jardim_bueno/jardim13.jpg"


function Index() {


    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                        O casal Rafaella e Ytalo nos procurou para fazer o projeto para seu primeiro apartamento após casados. A primeira casa da nova família. 
                        </p><br></br>
                        <p>
                        Com espaços reduzidos vem grandes responsabilidades!  O desafio do projeto foi adequar as necessidades do casal para o espaço de 66m2 do apartamento. O principal: eles queriam um espaço bom para receber amigos, acomodar a família e que também pudessem curtir a dois, no dia a dia. 
                        </p><br></br>
                        <p>
                        Buscamos aproveitar cada cantinho do apartamento e criar compartimentos secretos para armazenamento, como o banco que serve de assento para mesa e que também está cheio de gavetas para que eles utilizem. 
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image3} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image4} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h3} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                        Outro ponto de partida do projeto foi camuflar ou esconder ao máximo os equipamentos da cozinha para que o espaço ficasse mais clean e com menos informação, dessa forma criando uma organização visual. 
                        </p> <br></br>
                        <p>
                        O espelho também foi grande aliado nesse projeto, o utilizamos da forma mais funcional possível: ele reflete a vista do janelão do apartamento, o que traz a sensação de amplitude e duplica a iluminação do espaço. 
                        </p><br></br>
                        <p>
                        Em espaços menores, aqui no Studio, gostamos de setorizar bem as funções, o que organiza o espaço e dá uma sensação de amplitude. Nesse projeto podemos ver isso com a criação da caixa de madeira que esconde a área de serviço e a iluminação que demarca bem os espaços de estar, comer e servir. 
                        </p><br></br>
                        <p>
                        A criação da bancada de ilha, dividindo a cozinha, também foi uma forma de separar fisicamente os espaços integrados da sala e ainda ser funcional na hora de preparar e servir. Fizemos seu desenho com um lado arredondado, o que facilita a circulação no entorno e elimina quinas perigosas onde as pessoas podem se machucar.
                        </p><br></br>
                        <p>
                        Se você está sentindo falta daquele sofazão confortável para assistir tv, fique sabendo que criamos um espaço de televisão especialmente para isso e separado do living, onde a tv vai funcionar como plano de fundo para jantares animados entre amigos. 
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image5} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image6} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image7} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}  `}>
                    <img className={styles.imgGaleria} src={image8} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image9} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image10} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.ih1} `}>
                    <img className={styles.imgGaleria} src={image11} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.ih1}  `}>
                    <img className={styles.imgGaleria} src={image12} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} ${styles.iw4} `}>
                    <img className={styles.imgGaleria} src={image13} alt=""></img>
                </div>

            </div>
            <GoToTop />
        </div>
    )
}

export default Index;



