import title1 from "../../3projects/geral/geral_titles/geral1.svg"
import title2 from "../../3projects/geral/geral_titles/geral2.svg"
import styles from "./styles.module.css"
import GoToTop from "../../0components/GoToTop/GoToTop"
import image1 from "../../2assets/geral/geral1.jpg"
import image2 from "../../2assets/geral/geral2.jpg"
import image3 from "../../2assets/geral/geral3.jpg"
import image4 from "../../2assets/geral/geral4.jpg"
import image5 from "../../2assets/geral/geral5.jpg"
import image6 from "../../2assets/geral/geral6.jpg"
import image7 from "../../2assets/geral/geral8.jpg"
import image8 from "../../2assets/geral/geral9.jpg"
import image9 from "../../2assets/geral/geral10.jpg"
import image10 from "../../2assets/geral/geral11.jpg"
import image11 from "../../2assets/geral/geral12.jpg"
import image12 from "../../2assets/geral/geral13.jpg"
import image13 from "../../2assets/geral/geral15.jpg"
import image14 from "../../2assets/geral/geral16.jpg"
import image15 from "../../2assets/geral/geral18.jpg"
import image16 from "../../2assets/geral/geral19.jpg"
import image17 from "../../2assets/geral/geral20.jpg"
import image18 from "../../2assets/geral/geral21.jpg"
import image19 from "../../2assets/geral/geral24.jpg"
import image20 from "../../2assets/geral/geral25.jpg"
import image21 from "../../2assets/geral/geral26.jpg"
import image22 from "../../2assets/geral/geral30.jpg"
import image23 from "../../2assets/geral/geral31.jpg"
import image24 from "../../2assets/geral/geral32.jpg"
import image25 from "../../2assets/geral/geral33.jpg"


function Index() {


    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image17} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                        O Geral Sports Bar surgiu de uma sociedade voltada para o esporte e a necessidade de um espaço de reunião voltado para os jogos e campeonatos em um ano de Copa do Mundo. No espaço escolhido já funcionava um bar, dos mesmos donos, que decidiram renovar o ambiente e mudar a proposta para acompanhar um novo momento da sociedade. O retrofit também conta com a criação de um pavimento mezanino que abrigará os escritórios dos proprietários, que também são sócios da ”Ftboll” e precisavam de um espaço integrado para fazer reuniões.
                        </p><br></br>
                        <p>
                        Localizado no Setor Marista, bairro com vida noturna agitada em Goiânia, a infraestrutura do imóvel estava preparada com cozinha, bar e caixa definidos. Precisamos então transformar a atmosfera do local em um ambiente lúdico e leve. Uma das preocupações dos sócios era em fazer um espaço inclusivo, leve e que estimulasse o contato entre os clientes.
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image19} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image22} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image20} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image21} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image23} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h3} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                        Pensando em trabalhar  esportes variados, criamos ativações interativas para que os clientes pudessem ser transportados para outro ambiente: o portal da entrada que simula um gol, que com iluminação marca a entrada do bar e transporta o olhar da rua para dentro do bar. 
                        </p> <br></br>
                        <p>
                        Decidimos  trocar a estrutura da parede de entrada por uma estrutura metálica vazada, com rede de gol fazendo o fechamento. Dessa forma delimitamos o espaço mantendo a permeabilidade visual, assim como a circulação de ar pelo espaço. 
                        </p><br></br>
                        <p>
                        O espaço conta com uma área de jardim interna, que decidimos manter delimitando seu espaço com um grande banco, que o contorna e se transforma em uma arquibancada: elemento sempre presente nos estádios e que permite acomodar mais pessoas assistindo um jogo.
                        </p><br></br>
                        <p>
                        Nesse espaço descoberto revestimos o piso com o gramado artificial, simulando o gramado dos estádios. 
                        </p><br></br>
                        <p>
                        A parede lateral direita é a parede de “memórias”, onde celebramos campeões do esporte em momentos de glória, com fotos em preto e branco (formato mais famoso dessas fotos históricas). Os elementos nos guiam pelo corredor que hoje abriga mesas e levam para a área interna do bar onde estão concentrados o bar e a arquibancada.
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image14} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image15} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image3} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image4} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image5} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image6} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image9} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.textBlock} ${styles.iw2} ${styles.ih2} `}>
                    <div className={styles.textBox}>
                        <p>
                        A iluminação foi pensada para manter a atmosfera lúdica, mas de forma mais sugestiva e menos clara, por isso usamos lâmpadas e postes com formatos redondos que remetem à bolas.
                        </p><br></br>
                        <p>
                        No espaço interno, temos um ambiente fechado e intimista para quem deseja se concentrar nos jogos: trabalhamos o mobiliário leve, alto e funcional que já existia no espaço e decoramos com letreiros neon com frases e elementos do esporte. Assim como no espaço de auto-serviço do chopp que remete às pistas de atletismo mas mantendo a funcionalidade: os números das posições dividem os chopes do dia! 
                        </p><br></br>
                        <p>
                        Por ser um espaço fechado e com o pé-direito baixo (coisa que não podíamos alterar), buscamos uma iluminação confortável e mais rente ao teto, brincando com o campo de futebol e suas delimitações, assim como os espelhos de segurança que ampliam o espaço e deixam a atmosfera mais divertida. 
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}  ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image12} alt=""></img>
                </div>
             
                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image7} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image8} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image10} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image11} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image13} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2} `}>
                    <img className={styles.imgGaleria} src={image16} alt=""></img>
                </div>
 
                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image18} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.iw2} ${styles.ih3}`}>
                    <img className={styles.imgGaleria} src={image25} alt=""></img>
                </div>
              
                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image24} alt=""></img>
                </div>

            </div>
            <GoToTop />
        </div>
    )
}

export default Index;



