import React from "react";

function Contato(){

    return(
        <div>
            <h3>Página Contato </h3>
        </div>
    )
}

export default Contato;