import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Home from "./1pages/Home";
import Sobre from './1pages/Sobre';
import Projetos from './1pages/Projetos';
import Contato from './1pages/Contato';
import Melanina from "./3projects/melanina"
import LeblonCopa from "./3projects/leblon_copa"
import Cortela from "./3projects/cortela"
import LeblonBarra from "./3projects/leblon_barra"
import Azul from "./3projects/azul"
import Geral from "./3projects/geral"
import Gran from "./3projects/gran"
import CasaPedras from "./3projects/casa_pedras"
import PedrasInterior from "./3projects/pedras_interior"
import Unico from "./3projects/unico"
import Jardim from "./3projects/jardim_bueno"
import Lazer from "./3projects/lazer"

import {createBrowserRouter, RouterProvider} from "react-router-dom"

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children:[
      {
        path: "/",
        element: <Home/>
      },
      {
        path: "/sobre",
        element: <Sobre/>
      },
      {
        path: "/projetos",
        element: <Projetos/>,
      },
      {
        path: "/contato",
        element: <Contato/>
      },
      {
        path: "/melanina",
        element: <Melanina/>
      },
      {
        path: "/leblon_copa",
        element: <LeblonCopa/>
      },
      {
        path: "/cortela",
        element: <Cortela/>
      },
      {
        path: "/leblon_barra",
        element: <LeblonBarra/>
      },
      {
        path: "/azul",
        element: <Azul/>
      },
      {
        path: "/geral",
        element: <Geral/>
      },
      {
        path: "/gran_poeme",
        element: <Gran/>
      },
      {
        path: "/casa_pedras",
        element: <CasaPedras/>
      },
      {
        path: "/pedras_interior",
        element: <PedrasInterior/>
      },
      {
        path: "/unico",
        element: <Unico/>
      },
      {
        path: "/jardim",
        element: <Jardim/>
      },
      {
        path: "/lazer",
        element: <Lazer/>
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <RouterProvider router={router}/>
  </React.StrictMode>
);

