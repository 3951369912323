import React from "react";
import styles from "./style.module.css"
import title1 from "./page_titles/home_titles/title_home01.svg"
import title2 from "./page_titles/home_titles/title_home02.svg"
import { Link } from "react-router-dom"
import GoToTop from "../../0components/GoToTop/GoToTop";
import image1 from "../../2assets/melanina/melanina17.jpg"
import image2 from "../../2assets/ap_leblon_copa/ap_leblon_copa19.jpg"
import newImage1 from "../../2assets/ap_leblon_copa/ap_leblon_copa17.jpg"
import newImage2 from "../../2assets/ap_leblon_copa/ap_leblon_copa4.jpg"
import newImage3 from "../../2assets/melanina/melanina19.jpg"
import newImage4 from "../../2assets/ap_leblon_copa/ap_leblon_copa15.jpg"
import newImage5 from "../../2assets/ap_leblon_barra/ap_leblon_barra7.jpg"
import newImage6 from "../../2assets/cortela/cortela25.jpg"



function Home() {

    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w3} ${styles.iw2}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={newImage1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w6} ${styles.iw4}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={newImage2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w3} ${styles.iw3}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={newImage3} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.textBlock} ${styles.iw3}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            Olá, bem-vindo(a), somos o Studio Maggioni. Desenvolvemos projetos de arquitetura e design de interiores. Dedicamos toda a nossa criatividade e técnica para dar vida a projetos que traduzem a experiência que cada cliente deseja.
                        </p> <br></br>
                        <p>
                            Dá uma olhada nos nossos projetos comerciais e residenciais para ver qual deles mais encanta você!
                        </p><br></br>
                        <Link to="/projetos"><button className={`${styles.btn} ${styles.btnLeft}`}> ver projetos </button></Link>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w8} ${styles.iw3} ${styles.ih1}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w5} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={newImage5} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w3} ${styles.iw2}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={newImage4} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.textBlock} ${styles.iw3}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            Se o que você deseja são mudanças em uma área específica da sua casa ou do seu negócio, a consultoria deve ser a solução para você. Em um formato mais rápido e dinâmico  chegamos juntos a soluções criativas e eficientes.
                        </p> <br></br>
                        <Link to="/sobre"><button className={`${styles.btn} ${styles.btnLeft}`}> saiba mais </button></Link>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w8} ${styles.iw3} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w4} ${styles.iw3}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={newImage6} alt=""></img>
                </div>

            </div>
            <GoToTop></GoToTop>
        </div>
    )
}

export default Home;