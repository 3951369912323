import title1 from "./leblon_copa_titles/leblon_copa1.svg"
import title2 from "./leblon_copa_titles/leblon_copa2.svg"
import styles from "./styles.module.css"
import GoToTop from "../../0components/GoToTop/GoToTop"
import image1 from "../../2assets/ap_leblon_copa/ap_leblon_copa19.jpg"
import image2 from "../../2assets/ap_leblon_copa/ap_leblon_copa8.jpg"
import image3 from "../../2assets/ap_leblon_copa/ap_leblon_copa9.jpg"
import image4 from "../../2assets/ap_leblon_copa/ap_leblon_copa1.jpg"
import image5 from "../../2assets/ap_leblon_copa/ap_leblon_copa2.jpg"
import image6 from "../../2assets/ap_leblon_copa/ap_leblon_copa18.jpg"
import image7 from "../../2assets/ap_leblon_copa/ap_leblon_copa5.jpg"
import image8 from "../../2assets/ap_leblon_copa/ap_leblon_copa4.jpg"
import image9 from "../../2assets/ap_leblon_copa/ap_leblon_copa6.jpg"
import image10 from "../../2assets/ap_leblon_copa/ap_leblon_copa7.jpg"
import image11 from "../../2assets/ap_leblon_copa/ap_leblon_copa3.jpg"
import image12 from "../../2assets/ap_leblon_copa/ap_leblon_copa12.jpg"
import image13 from "../../2assets/ap_leblon_copa/ap_leblon_copa16.jpg"
import image14 from "../../2assets/ap_leblon_copa/ap_leblon_copa13.jpg"
import image15 from "../../2assets/ap_leblon_copa/ap_leblon_copa15.jpg"
import image16 from "../../2assets/ap_leblon_copa/ap_leblon_copa11.jpg"
import { Link } from "react-router-dom"


function Index() {


    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p className={styles.testimony}>
                            No meio da pandemia e de muitas incertezas, tive a felicidade de contratar uma exímia profissional, a Alexia tem um apreço  com sua profissão além da  vasta experiência, resultando numa satisfação total! Agradeço muito a Deus, de ter tido a sorte de contar com uma arquiteta sem igual! Fiquei totalmente contente com  seu projeto e execução. Super indico! <span> - Depoimento do cliente</span>
                        </p>
                    </div>
                </div>


                {/* segunda linha */}

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={styles.imgGaleria} src={image4} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h3} ${styles.w2} ${styles.textBlock} ${styles.ih3}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                        Esse é um daqueles projetos desafio. Primeiro, porque deveriam ser feitos dois projetos distintos para dois apartamentos com mesma planta no mesmo edifício, e o segundo, porque o cliente ainda não tinha refletido muito sobre o que desejava e quais seus gostos pessoais.
                        </p> <br></br>
                        <p>
                            O briefing se resumiu a poucas palavras: dourado, tons de madeira e "sou advogado". Aqui no site do estúdio você consegue ver o projeto "irmão" a esse clicando <Link to="/leblon_barra">aqui</Link>.
                        </p><br></br>
                        <p>
                        Com base nessas poucas informações e juntando o meu desejo de diferenciar os dois projetos pela materialidade, uma vez que o layout e o programa de necessidades era praticamente o mesmo, definimos uma paleta de tons quentes: a madeira mais amarelada, o couro caramelo combinados com tons neutros como o bege e branco e em alguns pontos de destaque a cor verde.
                        </p><br></br>
                        <p>
                        Isso claro, com os metais dourados e materiais polidos, um gosto em comum entre os clientes dos dois projetos. O objetivo foi criar um apartamento semelhante ao Leblon Barra, porém mantendo sua originalidade.
                        </p>
                    </div>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  ${styles.ih2}`}>
                    <img className={styles.imgGaleria} src={image3} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} $ ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image5} alt="" ></img>
                </div>


                {/* terceira linha */}

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw4} ${styles.ih2}`}>
                    <img className={styles.imgGaleria} src={image6} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} ${styles.iw4} `}>
                    <img className={styles.imgGaleria} src={image7} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image8} alt=""></img>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock} ${styles.ih1} `}>
                    <div className={styles.textBox}>
                        <p>
                        A disposição do mobiliário foi feita para o cliente receber amigos e familiares, seja ao redor da mesa, do fogão ou da tv. Para isso, criamos ambientes integrados mas ao mesmo tempo delimitados. Um exemplo é o sofá dupla face que desenhamos no escritório e que atende a tv ao mesmo tempo que a sala de jantar, do outro lado. Ele divide as salas mas também integra as duas.
                        </p><br></br>
                        <p>
                        Um ponto importante e de destaque para o projeto foi a cristaleira que criamos, com abertura para os dois lados: um que compõe o carrinho de bebidas e o outro com integração para a cozinha.
                        </p>
                    </div>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih2} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image9} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}  `}>
                    <img className={styles.imgGaleria} src={image10} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1} `}>
                    <img className={styles.imgGaleria} src={image11} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.ih2} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image16} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image12} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image13} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image15} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2} `}>
                    <img className={styles.imgGaleria} src={image14} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.textBlock}`}>
                    <div className={styles.textBox}>
                        <p>
                        Na suíte master, demos continuidade ao conceito utilizando texturas e tons quentes para trazer aconchego. Por conta do pilar na parede, optamos por fazer a cabeceira solta, que marcou bem o espaço da cama ao mesmo tempo que acolhe.
                        </p><br></br>
                        <p>
                        Nos armários, trouxemos a paleta de cores do ambiente, a madeira e o fendi e também optamos pela mudança de mdf para dividir o espaço das roupas como espaço do painel da tv. Demarcar bem os espaços, seja com cor ou com volumetria traz para o nosso cérebro a sensação de organização e beleza.
                        </p>
                    </div>
                </div>

            </div>
            <GoToTop />
        </div>
    )
}

export default Index;



