import React from "react";
import styles from "./styles.module.css"
import logoPink from "../../svg/logoPink.svg"
import igIcon from "../../svg/igIcon.svg"
import wppIcon from "../../svg/wppIcon.svg"
import { Link } from "react-router-dom"

function Footer() {

    return (
        <footer className={styles.footer}>
            <Link to="/"><div className={styles.infos}>
                <img src={logoPink} alt="logo studio maggioni" className={styles.logo}></img>
                {/* <p> arquitetura e design de interiores</p> */}
            </div></Link>
            <div className={styles.icons}>
                <img onClick={() => { window.location.href = "https://www.instagram.com/alexia_arquitetura/" }} src={igIcon} alt="icone instagram"></img>
                <img onClick={() => { window.location.href = "https://www.instagram.com/alexia_arquitetura/" }} src={wppIcon} alt="icone whatsapp"></img>
            </div>
        </footer>
    )
}

export default Footer;