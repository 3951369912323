import title1 from "../unico/unico_titles/unico1.svg"
import title2 from "../unico/unico_titles/unico2.svg"
import styles from "./styles.module.css"
import GoToTop from "../../0components/GoToTop/GoToTop"
import image1 from "../../2assets/unico/unico1.jpg"
import image2 from "../../2assets/unico/unico2.jpg"
import image3 from "../../2assets/unico/unico3.jpg"
import image4 from "../../2assets/unico/unico4.jpg"
import image5 from "../../2assets/unico/unico5.jpg"
import image6 from "../../2assets/unico/unico6.jpg"
import image7 from "../../2assets/unico/unico7.jpg"
import image8 from "../../2assets/unico/unico8.jpg"
import image9 from "../../2assets/unico/unico9.jpg"
import image10 from "../../2assets/unico/unico10.jpg"
import image11 from "../../2assets/unico/unico11.jpg"
import image12 from "../../2assets/unico/unico12.jpg"
import image13 from "../../2assets/unico/unico13.jpg"
import image14 from "../../2assets/unico/unico14.jpg"
import image15 from "../../2assets/unico/unico15.jpg"
import image16 from "../../2assets/unico/unico16.jpg"
import image17 from "../../2assets/unico/unico17.jpg"
import image18 from "../../2assets/unico/unico18.jpg"
import image19 from "../../2assets/unico/unico19.jpg"
import image20 from "../../2assets/unico/unico20.jpg"
import image21 from "../../2assets/unico/unico21.jpg"
import image22 from "../../2assets/unico/unico22.jpg"
import image23 from "../../2assets/unico/unico23.jpg"
import image24 from "../../2assets/unico/unico24.jpg"
import image25 from "../../2assets/unico/unico25.jpg"
import image26 from "../../2assets/unico/unico26.jpg"
import image27 from "../../2assets/unico/unico27.jpg"
import image28 from "../../2assets/unico/unico28.jpg"
import image29 from "../../2assets/unico/unico29.jpg"
import image30 from "../../2assets/unico/unico30.jpg"
import image31 from "../../2assets/unico/unico31.jpg"


function Index() {


    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            Apartamento contemporâneo, com muita leveza e toques de cor. A planta entregue pela construtora propicia o encontro e reunião de amigos e com o mobiliário não poderia ser diferente: escolhemos um mobiliário mais baixo e versátil, para que o casal pudesse brincar com sua versatilidade e montar diversas possibilidades dentro de um mesmo ambiente.
                        </p><br></br>
                        <p>
                            Com o uso de linhas retas repetidas em diferentes formatos, damos ritmo ao projeto, com organização visual e leveza. As linhas estão presentes no ripado do painel, que possui dois tamanhos, no vidro canelado do hall de entrada e até mesmo nas Persianas Twinline, criando uma unidade no apartamento com um todo.
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image3} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image4} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            As cores tem o papel de setorizar o apartamento: o hall, foi concebido para ser o espaço de proteção e iluminação com o oratório e o uso da cor azul trazendo paz ao ambiente.A cozinha, inteira branca, traz o aspecto de limpeza e organização e, quando aliada à madeira no módulo de organização, destaca a cristaleira e louças do casal.
                        </p> <br></br>
                        <p>
                            A suíte, aconchegante, mantém a base neutra do espaço social do apartamento, com destaque para a poltrona de leitura em terracota que cria um ponto focal no ambiente e traz um elemento surpresa.
                        </p><br></br>
                        <p>
                            A iluminação amarelada e bem distribuída é uma grande aliada no conforto visual e aconchego do apartamento. Buscamos ter pontos de luz em diversas alturas nas paredes, o que cria ambientes mais interessantes visualmente.
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image5} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image6} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw4} ${styles.ih2}`}>
                    <img className={styles.imgGaleria} src={image7} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}  `}>
                    <img className={styles.imgGaleria} src={image8} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image9} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image10} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.ih1} `}>
                    <img className={styles.imgGaleria} src={image11} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.ih1}  `}>
                    <img className={styles.imgGaleria} src={image12} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.ih1} ${styles.iw2} `}>
                    <img className={styles.imgGaleria} src={image13} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image14} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image15} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image16} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image17} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image18} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image19} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image20} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.iw4} ${styles.ih3}`}>
                    <img className={styles.imgGaleria} src={image21} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.iw4} ${styles.ih3}`}>
                    <img className={styles.imgGaleria} src={image22} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image23} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image24} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image25} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image26} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image27} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image28} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image31} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw4} ${styles.ih2}`}>
                    <img className={styles.imgGaleria} src={image30} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.iw4} ${styles.ih3}`}>
                    <img className={styles.imgGaleria} src={image29} alt=""></img>
                </div>

            </div>
            <GoToTop />
        </div>
    )
}

export default Index;



