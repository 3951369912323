import GoToTop from "../../0components/GoToTop/GoToTop"
import styles from "./styles.module.css"
import title1 from "../gran/gran_titles/gran1.svg"
import title2 from "../gran/gran_titles/gran2.svg"
import image1 from "../../2assets/gran/gran1.jpg"
import image2 from "../../2assets/gran/gran2.jpg"
import image3 from "../../2assets/gran/gran3.jpg"
import image4 from "../../2assets/gran/gran5.jpg"
import image5 from "../../2assets/gran/gran4.jpg"
import image6 from "../../2assets/gran/gran6.jpg"
import image7 from "../../2assets/gran/gran7.jpg"
import image8 from "../../2assets/gran/gran10.jpg"
import image9 from "../../2assets/gran/gran11.jpg"
import image10 from "../../2assets/gran/gran12.jpg"
import image11 from "../../2assets/gran/gran13.jpg"
import image12 from "../../2assets/gran/gran15.jpg"
import image13 from "../../2assets/gran/gran16.jpg"
// import image14 from "../../2assets/gran/gran19.jpg"
import image15 from "../../2assets/gran/gran20.jpg"
import image16 from "../../2assets/gran/gran22.jpg"
import image17 from "../../2assets/gran/gran23.jpg"
import image18 from "../../2assets/gran/gran24.jpg"
import image19 from "../../2assets/gran/gran25.jpg"
import image20 from "../../2assets/gran/gran28.jpg"
import image21 from "../../2assets/gran/gran29.jpg"
import image22 from "../../2assets/gran/gran30.jpg"
import image23 from "../../2assets/gran/gran33.jpg"
import image24 from "../../2assets/gran/gran34.jpg"
import image25 from "../../2assets/gran/gran35.jpg"
import image26 from "../../2assets/gran/gran36.jpg"
import image27 from "../../2assets/gran/gran37.jpg"
import image28 from "../../2assets/gran/gran40.jpg"
import image29 from "../../2assets/gran/gran43.jpg"



function Index() {


    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image8} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p className={styles.testimony}>
                            Ahhh, Alexia.
                            Aquela que participou e projetou meu primeiro grande passo: meu apartamento!
                            Não se tratava de um simples imóvel. Eram os poucos metros quadrados mais bem sonhados de uma vida. Ela chegou e colocou no papel, como se lesse minha mente.
                            A execução foi ainda melhor. Ela entende e respeita suas vontades, mas não deixa de colocar o jeito Alexia de ser. É esse o segredo.
                            Mas a família se formou. Veio esposo e filho. Não tinha jeito. O sonho aumentou. Ninguém mais poderia ser responsável por materializar esse lindo momento, senão ela. Como já esperávamos, o fez com maestria. Posso dizer que, sim, tenho a casa dos meus sonhos, com a minha família, assinado por ela. Obrigada, de todo o coração, por ser simplesmente a melhor. <span>- Depoimento da cliente.</span>
                        </p>

                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image3} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image4} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h3} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            Esse foi o terceiro projeto que fizemos para Roberta, já participamos da sua vida profissional quando fizemos seu escritório e da sua vida solteira quando fizemos seu primeiro apartamento. Esse é o nosso primeiro projeto para a família.
                        </p> <br></br>
                        <p>
                            A Roberta e o Antônio são um casal jovem, com seu primeiro filho, conquistando o sonho da primeira casa. Por  já  termos feito outros projetos para Roberta,  conheciamos seu gosto pessoal e suas preferências para arquitetura: um estilo mais clássico, com muitas influências da sua profissão como advogada. Mas esse projeto teve um diferencial: o Antônio.  Antônio tem um gosto estético e visual quase oposto ao da Roberta: com influências industriais, uso de metais e materiais mais naturais como cimento, madeira e pedras.
                        </p><br></br>
                        <p>
                            Nosso desafio foi encontrar o ponto em comum entre essas preferências tão distintas, o qual encontramos na cor preta: os dois lados do casal gostam dessa cor. Nosso trabalho a partir daí foi encontrar o equilíbrio perfeito em torno da cor escolhida, o que resultou em uma mistura contemporânea de estilos, unindo elementos atemporais do estilo clássico com a atemporalidade dos materiais in natura.
                        </p> <br></br>
                    </div>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image13} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image5} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2} `}>
                    <img className={styles.imgGaleria} src={image9} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image11} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image15} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1} `}>
                    <img className={styles.imgGaleria} src={image12} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image10} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image16} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock} ${styles.ih1} ${styles.ih2}`}>
                    <div className={styles.textBox}>
                        <p>
                        A partir daí criamos uma paleta de cores, que complementam o preto, como os mais variados tons de cinza, mesclados com o fendi e o bege, que trazem um aspecto mais clássico para o projeto. Os tons terrosos da madeira e das poltronas trouxeram o conforto visual que cria um equilíbrio à frieza da cor preta. 
                        </p><br></br>
                        <p>
                        Por se tratar de um projeto com  influências  industriais, brincamos bastante com as texturas e linhas, para criar um conforto e ambiente aconchegante.
                        </p><br></br>
                        <p>
                        Para além das referências estéticas, o interior da casa foi desenhado pensando em sua funcionalidade. O casal adora receber e tem uma rede de amigos bem unida. Para isso, criamos um mobiliário de apoio para festas, com direito a chopeira embutida e espaço para armazenamento de louças.
                        </p><br></br>
                        <p>
                        No segundo piso da casa, onde está concentrada a área íntima, demos continuidade com a materialidade da área social, porém trazendo elementos um pouco mais clássicos e refinados, como a Boisserie. Criando uma mistura perfeita de dois estilos diferentes, entre Antônio e Roberta.
                        </p><br></br>
                        <p>
                        Não podemos deixar de falar, é claro, sobre o quarto do Miguel, seu espaço na casa permite sua liberdade criativa através da atmosfera lúdica, para que ele possa descobrir qual estilo seguir.
                        </p><br></br>
                    </div>
                </div>
                
                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2} `}>
                    <img className={styles.imgGaleria} src={image17} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image18} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image19} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image20} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image22} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  ${styles.ih2} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image21} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image23} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image24} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image25} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih1} ${styles.iw2} `}>
                    <img className={styles.imgGaleria} src={image27} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}  ${styles.ih2} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image26} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image28} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.ih1} `}>
                    <img className={styles.imgGaleria} src={image29} alt=""></img>
                </div>

                 <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image7} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image6} alt=""></img>
                </div>

            </div>
            <GoToTop />
        </div>
    )
}

export default Index;



