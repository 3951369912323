import title1 from "../cortela/cortela_titles/cortela1.svg"
import title2 from "../cortela/cortela_titles/cortela2.svg"
import styles from "./styles.module.css"
import GoToTop from "../../0components/GoToTop/GoToTop"
import image1 from "../../2assets/cortela/cortela1.jpg"
import image2 from "../../2assets/cortela/cortela3.jpg"
import image3 from "../../2assets/cortela/cortela5.jpg"
import image4 from "../../2assets/cortela/cortela6.jpg"
import image5 from "../../2assets/cortela/cortela7.jpg"
import image6 from "../../2assets/cortela/cortela9.jpg"
import image7 from "../../2assets/cortela/cortela10.jpg"
import image8 from "../../2assets/cortela/cortela12.jpg"
import image9 from "../../2assets/cortela/cortela13.jpg"
import image10 from "../../2assets/cortela/cortela15.jpg"
import image11 from "../../2assets/cortela/cortela16.jpg"
import image12 from "../../2assets/cortela/cortela17.jpg"
import image13 from "../../2assets/cortela/cortela18.jpg"
import image14 from "../../2assets/cortela/cortela19.jpg"
import image15 from "../../2assets/cortela/cortela21.jpg"
import image16 from "../../2assets/cortela/cortela24.jpg"
import image17 from "../../2assets/cortela/cortela25.jpg"
import image18 from "../../2assets/cortela/cortela26.jpg"
import image19 from  "../../2assets/cortela/cortela14.jpg"


function Index() {


    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            A Casa Cortela é um restaurante e cafeteria localizada no Setor Bueno em Goiânia, uma região com muitos prédios residenciais e comerciais. Os proprietários me procuraram porque viram naquele ambiente a necessidade de um espaço confortável e com comidinhas gostosas.
                        </p> <br></br>
                        <p>
                            O Eduardo e o Rodrigo chegaram até nós por duas indicações diferentes e quando fizemos nossa primeira reunião já vimos que seria uma boa parceria. Os proprietários nos procuraram quando estavam definindo o imóvel e começamos a conversar a partir daí.
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image4} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={styles.imgGaleria} src={image1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h3} ${styles.w2} ${styles.textBlock} ${styles.ih3}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            O ponto era antes uma padaria, que havia sido recém-reformada. Não precisamos mexer na cozinha, que já estava com todos os pontos necessários, portanto focamos nas adequações necessárias no salão e espaços para clientes.
                        </p><br></br>
                        <p>
                            A ideia inicial era fazer um espaço aconchegante e que acolhesse os moradores da região, mantendo uma relação próxima com os clientes.
                        </p><br></br>
                        <p>
                            O centro da proposta é a “comida caseira” e tudo que ela remete, como os ambientes das casas de mães e avós. Buscamos então inspiração nas casas de nossos avós, o que nos trouxe a um elemento em comum: tijolo vazado, ou cobogó.
                        </p><br></br>
                        <p>
                            O cobogó é um elemento vazado, que permite o fechamento de espaços, mantendo a permeabilidade e passagem da luz e ventilação. Foi muito difundido na arquitetura brasileira e usado de diversas formas. 
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  ${styles.ih2}`}>
                    <img className={styles.imgGaleria} src={image3} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} $ ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image5} alt="" ></img>
                </div>

                {/* terceira linha */}

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw4} ${styles.ih2}`}>
                    <img className={styles.imgGaleria} src={image6} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} ${styles.iw4} `}>
                    <img className={styles.imgGaleria} src={image7} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image8} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock} ${styles.ih2} `}>
                    <div className={styles.textBox}>
                        <p>
                        Quando vimos o Cobogó Sol nos apaixonamos e a partir daí ele se tornou o ponto norteador do projeto.
                        Tomamos partido do cobogó para criar a nova fachada do estabelecimento, e utilizando o padrão formando um sol, criamos uma fachada unitária com leveza e repetição, mantendo a ventilação e iluminação natural.
                        </p><br></br>
                        <p>
                        Pelo custo demos preferência para o cobogó de barro e sua cor avermelhada acabou guiando a paleta de cores que usamos no interior do projeto: terracota e verde capim, combinados com uma paleta neutra de cinzas provenientes do concreto e cimento existentes no ambiente.
                        </p><br></br>
                        <p>
                        Por se tratar de um espaço com comida caseira e natural, optamos por materiais naturais como o tijolinho (que já estava no imóvel) o concreto e o cimento combinados com um paisagismo de folhagens que acompanhou a árvore que existia no local. 
                        </p>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image9} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw4} ${styles.ih2}  `}>
                    <img className={styles.imgGaleria} src={image10} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}  `}>
                    <img className={styles.imgGaleria} src={image11} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.ih2} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image16} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h3} ${styles.w4} ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image12} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <div className={styles.textBox}>
                        <p>
                        Um dos grandes desafios do projeto foi transformar o local com poucas, ou quase nenhuma, alteração estrutural: apenas abrimos um pouco o vão que já existia na parede e substituímos o portão de metal por uma esquadria fixa de vidro, que trouxe iluminação natural para dentro do primeiro salão. Aproveitamos até os pontos de iluminação que já existiam no local.
                        </p><br></br>
                        <p>
                        Quando vamos projetar espaços de alimentação como cafés, bares e restaurantes gostamos de pensar em várias possibilidades para o cliente que irá frequentar: mesas grandes que acolhem grupos, mesas mais intimistas para casais e as mais diversas configurações. Por isso, escolhemos um mobiliário diverso e flexível, que os proprietários podem movimentar e mudar a disposição de acordo com as necessidades.
                        </p><br></br>
                        <p>
                        Um desafio sempre presente em projetos de hospitalidade é  conseguir atender a maior quantidade de clientes possível em um espaço confortável e que não dê a sensação de estar tumultuado. Pensando nisso, criamos um banco longitudinal que acompanha a maior parede do espaço, permitindo que mais pessoas se sentem em uma mesma mesa, de forma confortável. 
                        </p><br></br>
                        <p>
                        Curiosidade importante: os pendentes no caixa foram criados pelo Studio, um elemento diferenciado e de baixo investimento. Desenvolvemos o pendente utilizando um sousplat artesanal e uma lâmpada que por aqui amamos: a lâmpada Balloon.
                        </p>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2} `}>
                    <img className={styles.imgGaleria} src={image14} alt=""></img>
                </div>

                 <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image13} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image15} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image19} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image17} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image18} alt=""></img>
                </div>

            </div>
            <GoToTop />
        </div>
    )
}

export default Index;



