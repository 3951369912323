import title1 from "../azul/azul_titles/azul2.svg"
import title2 from "../azul/azul_titles/azul3.svg"
import styles from "./styles.module.css"
import GoToTop from "../../0components/GoToTop/GoToTop"
import image1 from "../../2assets/azul/azul1.jpg"
import image2 from "../../2assets/azul/azul2.jpg"
import image3 from "../../2assets/azul/azul3.jpg"
import image4 from "../../2assets/azul/azul4.jpg"
import image5 from "../../2assets/azul/azul5.jpg"
import image6 from "../../2assets/azul/azul6.jpg"
// import image7 from "../../2assets/azul/azul7.jpg"
import image8 from "../../2assets/azul/azul8.jpg"
import image9 from "../../2assets/azul/azul9.jpg"
import image10 from "../../2assets/azul/azul10.jpg"
import image11 from "../../2assets/azul/azul11.jpg"
// import image12 from "../../2assets/azul/azul12.jpg"
import image13 from "../../2assets/azul/azul13.jpg"
import image14 from "../../2assets/azul/azul14.jpg"
// import image15 from "../../2assets/azul/azul15.jpg"
import image16 from "../../2assets/azul/azul16.jpg"
import image17 from "../../2assets/azul/azul17.jpg"
import image18 from "../../2assets/azul/azul18.jpg"
import image19 from "../../2assets/azul/azul19.jpg"
import image20 from "../../2assets/azul/azul20.jpg"
import image21 from "../../2assets/azul/azul21.jpg"
import image22 from "../../2assets/azul/azul22.jpg"
import image23 from "../../2assets/azul/azul23.jpg"
import image24 from "../../2assets/azul/azul24.jpg"
import image25 from "../../2assets/azul/azul25.jpg"
import image26 from "../../2assets/azul/azul26.jpg"
import image27 from "../../2assets/azul/azul27.jpg"
import image28 from "../../2assets/azul/azul28.jpg"
import image29 from "../../2assets/azul/azul29.jpg"
import image30 from "../../2assets/azul/azul30.jpg"
import image31 from "../../2assets/azul/azul31.jpg"
import image32 from "../../2assets/azul/azul32.jpg"
import image33 from "../../2assets/azul/azul33.jpg"
import image34 from "../../2assets/azul/azul34.jpg"
import image35 from "../../2assets/azul/azul35.jpg"
import image36 from "../../2assets/azul/azul36.jpg"
import image37 from "../../2assets/azul/azul37.jpg"
import image38 from "../../2assets/azul/azul38.jpg"
import image39 from "../../2assets/azul/azul39.jpg"
import image40 from "../../2assets/azul/azul40.jpg"
import image41 from "../../2assets/azul/azul41.jpg"
import image42 from "../../2assets/azul/azul42.jpg"



function Index() {


    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            O Marlon já havia sido nosso cliente em outros dois projetos para o seu escritório e sempre nos dizia que queria mudar para um lugar maior agora que tinha tido uma filha. Ele diz que não gosta de obras, então adquiriu um lugar que já tinha uma infraestrutura básica pronta, com armários, bancadas e iluminação. O problema é que o gosto dos antigos moradores era completamente diferente do gosto do casal: as paredes estavam revestidas com tijolinhos, o mobiliário bem rústico com cores pesadas, a cozinha com armários pretos e bancadas escuras.
                        </p><br></br>
                        <p>
                            Eles queriam um projeto com personalidade e ambientes aconchegantes, porém sem o uso de materiais amadeirados. Queriam um apartamento “moderno”, usando apenas tons de cinza e azul, que é a cor preferida do casal e que nomeia o projeto.
                        </p><br></br>
                    </div>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image25} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image26} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image3} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image6} alt="" ></img>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            Um fato determinante foi o de que o casal tem dois filhos pequenos, por isso a família queria um mobiliário resistente e seguro para as crianças e uma disposição mais livre no apartamento para que eles pudessem brincar e circular livremente pelos espaços, sem muitas quinas e móveis pelo caminho.
                        </p> <br></br>
                        <p>
                            O casal gosta de receber os amigos no espaço, então precisavam de ambientes flexíveis e que acomodassem muitas pessoas ao mesmo tempo. O principal ponto de encontro: a churrasqueira.
                        </p><br></br>
                        <p>
                            Traçamos o programa de necessidades do projeto e definimos as palavras de ordem: praticidade, conforto e modernidade.
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image5} alt=""></img>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} `}>
                    <img className={styles.imgGaleria} src={image4} alt=""></img>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} ${styles.iw4} `}>
                    <img className={styles.imgGaleria} src={image8} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={styles.imgGaleria} src={image9} alt=""></img>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock} `}>
                    <div className={styles.textBox}>
                        <p>
                            Começamos o projeto pela cozinha, o coração da casa, que deveria ser funcional e prática acima de tudo, para isso escolhemos cores claras e materiais de alta resistência: Quartzito Mont Blanc nas bancadas e revestimento White Bubble da Portobello nas paredes. Além disso, criamos uma bancada de refeições rápidas dentro da cozinha, para o café da manhã e lanches durante a semana
                        </p><br></br>
                        <p>
                            Seguimos a linha do cinza e branco para o restante do apartamento, mantendo o padrão da bancada para a churrasqueira, combinando com o revestimento cinza Pietra Lombardia Grigio da Portobello, que trouxe contraste com o Quartzito Mont Blanc ainda mantendo uma unidade de projeto.
                        </p><br></br>
                        <p>
                            Para o espaço da varanda pensamos em mobiliários mais soltos e próprios para a área externa com o objetivo de trazer a atmosfera do churrasco ao ar livre no conforto do apartamento.
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image10} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2} `}>
                    <img className={styles.imgGaleria} src={image11} alt=""></img>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image13} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image14} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.textBlock} ${styles.ih1}`}>
                    <div className={styles.textBox}>
                        <p>
                            Para a sala de tv, criamos um volume quadrado e simétrico para o painel pensado para ser o ponto focal da sala.  No hall de entrada, aproveitamos para usar bem a cor azul nas paredes, teto e porta criando um espaço único e de impacto à primeira vista.
                        </p><br></br>
                        <p>
                            Curiosidade: o casal não gosta de madeira nem de tons amadeirados. Um dos grandes desafios do projeto foi desenvolver ambientes aconchegantes sem usar a madeira (um dos nossos materiais preferidos).
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image16} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image17} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image18} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image19} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image20} alt=""></img>
                </div>

               

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image21} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih1} ${styles.iw2} `}>
                    <img className={styles.imgGaleria} src={image22} alt=""></img>
                </div>






                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image23} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.ih1} ${styles.iw2}  `}>
                    <img className={styles.imgGaleria} src={image24} alt=""></img>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image27} alt=""></img>
                </div>



                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image28} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image29} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image30} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image31} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image32} alt=""></img>
                </div>




                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image33} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image34} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image35} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image36} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image37} alt=""></img>
                </div>



                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image38} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image39} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image40} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image41} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image42} alt=""></img>
                </div>




            </div>
            <GoToTop />
        </div>
    )
}

export default Index;



