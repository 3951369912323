import React from "react"
import styles from './styles.module.css';
import logoPink from "../../svg/logoPink.svg"
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";


function Header() {

  const [toggler, setToggler] = useState(true);
  useEffect(()=>{
    var uno = window.document.getElementById("uno");
    console.log(uno)
  }, [toggler])

    return (
      <header className={styles.header}>
        <div>
         <Link to="/"><img src={logoPink} className={styles.logo} alt="studio_logo"></img></Link> 
        </div>
        <Menu></Menu>
        </header>
    );

    function uncheck(){
      if(toggler === true){
        return setToggler(false)
      }
      else{
        return setToggler(true);
      }
    }

    function Menu(){
      return(
        <div className={styles.menuWrap}>
          <input type="checkbox" className={styles.toggler} id="uno" ></input>
          <div className={styles.hamburger}><div></div></div>
          <nav className={styles.menu}>
            <div>
              <div>
                <ul>
                  <li onClick={uncheck}><Link to="/">home</Link></li>
                  <li onClick={uncheck}><Link to="/projetos">projetos</Link></li>
                  <li onClick={uncheck}><Link to="/sobre">sobre / contato</Link></li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      )
  }

}
  
  
  export default Header;
  