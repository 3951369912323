import title1 from "../leblon_barra/leblon_barra_titles/leblon_barra1.svg"
import title2 from "../leblon_barra/leblon_barra_titles/leblon_barra2.svg"
import styles from "./styles.module.css"
import GoToTop from "../../0components/GoToTop/GoToTop"
import image1 from "../../2assets/ap_leblon_barra/ap_leblon_barra1.jpg"
import image2 from "../../2assets/ap_leblon_barra/ap_leblon_barra2.jpg"
import image3 from "../../2assets/ap_leblon_barra/ap_leblon_barra3.jpg"
import image4 from "../../2assets/ap_leblon_barra/ap_leblon_barra4.jpg"
import image5 from "../../2assets/ap_leblon_barra/ap_leblon_barra5.jpg"
import image6 from "../../2assets/ap_leblon_barra/ap_leblon_barra6.jpg"
import image7 from "../../2assets/ap_leblon_barra/ap_leblon_barra7.jpg"
import image8 from "../../2assets/ap_leblon_barra/ap_leblon_barra8.jpg"
import image9 from "../../2assets/ap_leblon_barra/ap_leblon_barra9.jpg"
import image10 from "../../2assets/ap_leblon_barra/ap_leblon_barra10.jpg"
import image11 from "../../2assets/ap_leblon_barra/ap_leblon_barra11.jpg"
import image12 from "../../2assets/ap_leblon_barra/ap_leblon_barra12.jpg"
import image13 from "../../2assets/ap_leblon_barra/ap_leblon_barra13.jpg"
import image14 from "../../2assets/ap_leblon_barra/ap_leblon_barra14.jpg"
import image15 from "../../2assets/ap_leblon_barra/ap_leblon_barra15.jpg"
import image16 from "../../2assets/ap_leblon_barra/ap_leblon_barra16.jpg"
import image17 from "../../2assets/ap_leblon_barra/ap_leblon_barra17.jpg"
import image18 from "../../2assets/ap_leblon_barra/ap_leblon_barra18.jpg"
import image19 from "../../2assets/ap_leblon_barra/ap_leblon_barra19.jpg"
import image20 from "../../2assets/ap_leblon_barra/ap_leblon_barra20.jpg"
// import image21 from "../../2assets/ap_leblon_barra/ap_leblon_barra21.jpg"
import image22 from "../../2assets/ap_leblon_barra/ap_leblon_barra22.jpg"


function Index() {


    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image8} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                        O Bruno é construtor e já nos conhecíamos de projetos anteriores que o Studio desenvolveu para ele construir e vender. Dessa vez, ele nos contratou para fazer um projeto pessoal, com uma questão: desenvolver dois apartamentos, um para ele e outro para seu primo. Eles compraram apartamentos iguais (com plantas refletidas) em um mesmo prédio e o Bruno tomaria conta do projeto dos dois (Leblon Barra e Leblon Copa).
                        </p><br></br>
                        <p>
                        Topamos o desafio de fazer dois projetos com necessidades muito semelhantes, entretanto deveríamos apresentar propostas diferentes.
                        </p><br></br>
                        <p>
                        O cliente nos deixou livre para criar o projeto, mas também deixou bem claro os desejos que tinha: metais dourados, painel ripado na sala, materiais com brilho e reflexivos. 
                        </p>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image3} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image4} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                        Neste apartamento em questão optamos por trabalhar com a cor preta (a preferida do cliente) combinada com uma madeira mais clara para criar contraste. A cor azul para pontos em destaque, combinado com cores neutras e secundárias, como o branco, bege e cinza deixam o ambiente mais leve. 
                        </p> <br></br>
                        <p>
                        Para trazer conforto e acolhimento, trabalhamos com a iluminação amarelada em diversos pontos e o uso do metal dourado (um metal quente que também é uma preferência do cliente). Mesmo com a utilização de cores frias, decidimos usar revestimentos com fundos quentes, como o Gouache Nuage da Portobello que trouxe todo o charme para a cozinha e o porcelanato marmorizado com tons quentes para o painel da tv. 
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image7} alt=""></img>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image5} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw4} ${styles.ih2}`}>
                    <img className={styles.imgGaleria} src={image6} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}  `}>
                    <img className={styles.imgGaleria} src={image12} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image1} alt=""></img>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.textBlock} `}>
                    <div className={styles.textBox}>
                        <p>
                        O cliente gosta de receber amigos em casa e fazer jantares, para isso, criamos uma bancada mais alta, que faz a passagem da cozinha para a sala, integrando os dois ambientes de forma harmônica. Por ser uma bancada somente de apoio e não de preparo, usamos um porcelanato com a estética de uma pedra, porém com custo benefício melhor.
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image9} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image10} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image11} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image13} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image15} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock} ${styles.ih1}`}>
                    <div className={styles.textBox}>
                        <p>
                        Um ponto de destaque desse apartamento é o escritório. O cliente queria um espaço para expor sua coleção de action figures. Mantendo a estética do projeto, decidimos criar um ambiente de exposição onde cada action figure tivesse seu lugar determinado. Pelo espaço do escritório ser um pouco menor, decidimos colocá-los em armários contornando o ambiente e deixando o espaço central livre para trabalho e descanso. 
                        </p><br></br>
                        <p>
                        Para a suíte master, mantivemos a ideia e estética do projeto e trabalhamos com uma cabeceira acolchoada na parede, trazendo amplitude e conforto para o quarto. 
                        </p><br></br>              
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image17} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image16} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image14} alt=""></img>
                </div>
                
                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image18} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image19} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.textBlock}`}>
                    <div className={styles.textBox}>
                        <p>
                        Curiosidades: o apartamento foi inteiro automatizado e é controlado por voz e controles, por isso quase não vemos interruptores no projeto. Logo após a entrega cliente recebeu uma excelente oferta no apartamento e o vendeu por um valor bem acima do adquirido pela construtora. 
                        </p>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image20} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.iw4} ${styles.ih3}`}>
                    <img className={styles.imgGaleria} src={image22} alt=""></img>
                </div>

              

                








            </div>
            <GoToTop />
        </div>
    )
}

export default Index;



