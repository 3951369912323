import title1 from "../casa_pedras/pedras_titles/pedras1.svg"
import title2 from "../casa_pedras/pedras_titles/pedras2.svg"
import styles from "./styles.module.css"
import GoToTop from "../../0components/GoToTop/GoToTop"
import image1 from "../../2assets/casa_pedras/pedras1.jpg"
import image2 from "../../2assets/casa_pedras/pedras2.jpg"
import image3 from "../../2assets/casa_pedras/pedras3.jpg"
import image4 from "../../2assets/casa_pedras/pedras4.jpg"
import image5 from "../../2assets/casa_pedras/pedras5.jpg"
import image6 from "../../2assets/casa_pedras/pedras6.jpg"
import image7 from "../../2assets/casa_pedras/pedras7.jpg"
import image8 from "../../2assets/casa_pedras/pedras8.jpg"
import image9 from "../../2assets/casa_pedras/pedras9.jpg"
import image10 from "../../2assets/casa_pedras/pedras10.jpg"

function Index() {


    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image8} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            O Lucas é engenheiro e já havíamos trabalhado juntos em outras obras, que ele executou. Desde então foi notável o interesse dele pela arquitetura e o que é possível criar quando juntamos arquitetura e engenharia.
                            Quando foi a vez dele construir a casa dos seus sonhos, juntamente com a Angélica, eles nos chamaram e nos deram total liberdade criativa para desenvolver o projeto do seu novo lar.
                        </p><br></br>
                        <p>
                            O gosto para arquitetura do casal é muito parecido com as referências do Studio: gostamos de materiais naturais e muitas vezes rústicos como a madeira e o concreto. O gosto por uma fachada geométrica e mais discreta também estão em nossas preferências.
                        </p><br></br>
                        <p>
                            Criamos assim, uma arquitetura cheia de contrastes e texturas: do concreto com a natureza, da madeira com a pedra, dos cheios e vazios.
                        </p>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image3} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image4} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.ih3}${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                        A casa foi pensada para ser um refúgio em meio a cidade, com espaços de contemplação e conexão com a natureza. Onde o casal pode se desligar da correria cotidiana e planejar seus próximos passos como família. 
                        </p> <br></br>
                        <p>
                        Para a criação do projeto, setorizamos a casa e seus usos, o que pode ser muito percebido em sua volumetria: o volume principal está localizado no nível térreo e é onde serão desenvolvidas as atividades sociais e de serviço da casa. Nesse mesmo nível, está localizado o volume do “spa”  que atende a área da piscina com uma sauna, duchas e contato com a natureza. 
                        </p><br></br>
                        <p>
                        Esses dois volumes são os pilares para o volume íntimo, que está localizado no primeiro pavimento. Nesse espaço temos um escritório para uso da proprietária e as suítes da casa.  
                        </p><br></br>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih2} `}>
                    <img className={styles.imgGaleria} src={image7} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} ${styles.iw4} `}>
                    <img className={styles.imgGaleria} src={image5} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image6} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2} `}>
                    <img className={styles.imgGaleria} src={image9} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image10} alt=""></img>
                </div>

            </div>
            <GoToTop />
        </div>
    )
}

export default Index;



