import React from "react"
import styles from "./styles.module.css"
import image1 from "../../2assets/melanina/melanina17.jpg"
import title1 from "../melanina/melanina_titles/melanina1.svg"
import image3 from "../../2assets/melanina/melanina13.jpg"
import image4 from "../../2assets/melanina/melanina2.jpg"
import image5 from "../../2assets/melanina/melanina12.jpg"
import image6 from "../../2assets/melanina/melanina1.jpg"
import image7 from "../../2assets/melanina/melanina6.jpg"
import image8 from "../../2assets/melanina/melanina9.jpg"
import title2 from "../melanina/melanina_titles/melanina2.svg"
import image9 from "../../2assets/melanina/melanina7.jpg"
import image10 from "../../2assets/melanina/melanina5.jpg"
import image11 from "../../2assets/melanina/melanina18.jpg"
import image12 from "../../2assets/melanina/melanina19.jpg"
import image13 from "../../2assets/melanina/melanina20.jpg"
import image14 from "../../2assets/melanina/melanina15.jpg"
import image15 from "../../2assets/melanina/melanina14.jpg"
import image16 from "../../2assets/melanina/melanina21.jpg"

function Melanina() {

    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>
                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={`${styles.imgGaleria} ${styles.firstImage}`} src={image1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            Melanina é um bar e boate localizado no Setor Serrinha em Goiânia. O empreendimento nasceu com a proposta de integrar os dois diferentes ambientes de forma harmônica.
                        </p><br></br>
                        <p>
                            O Studio participou do desenvolvimento do bar desde o início, quando ainda não tinha
                            nome nem conceito, então fomos além do projeto e criamos a concepção da marca a
                            partir da arquitetura.
                        </p><br></br>
                        <p>
                            O objetivo era criar um bar externo, com ambiente leve, tropical com conexão para a rua, onde o público se sentisse à vontade para passar o dia. A referência foi o ambiente praiano do Rio de Janeiro com influencias do estilo Osklen: casual e diurno.
                        </p>
                    </div>
                </div>

                {/* segunda linha */}

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image5} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image3} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw4} ${styles.ih3}`}>
                    <img className={styles.imgGaleria} src={image4} alt="" ></img>
                </div>


                {/* terceira linha */}

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image6} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} `}>
                    <img className={styles.imgGaleria} src={image7} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image8} alt=""></img>
                </div>


                {/* quarta linha */}

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title2} alt=""></img>
                </div>


                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image9} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <div className={styles.textBox}>
                        <p>
                            O imóvel havia sido uma boate anteriormente, e já contava com toda a infraestrutura interna necessária. O grande desafio foi a área externa: para criar uma conexão entre os clientes e o entorno, abrimos o bar, criando um pórtico na fachada com o uso de elementos horizontais e com demarcação de entrada.
                        </p><br></br>
                        <p>
                            Um dos destaques é o projeto luminotécnico. Por ser um ambiente aberto e sem pontos de fixação no teto, desenvolvemos uma iluminação com pontos de luz espalhadas em diferentes alturas, evitando o foco de luz direto nos clientes e criando espaços mais aconchegantes.
                        </p><br></br>
                        <p>
                            O destaque fica para o balcão iluminado , que emana a luz de forma difusa e agradável, na altura dos olhos, mas que também funciona como um elemento de fachada. Os leds nas paredes funcionam como arandelas que iluminam os jardins e valorizam o espaço.
                        </p>
                    </div>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image10} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image11} alt=""></img>
                </div>


                {/* novas linhas */}

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image12} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image13} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}`}>
                    <img className={styles.imgGaleria} src={image14} alt=""></img>
                </div>

                {/* ultimo texto */}

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.textBlock}`}>
                    <div className={styles.textBox}>
                    <p>
                        O espaço de bar é o ponto focal e principal do projeto, tanto na escolha estética quanto na sua funcionalidade.
                    </p><br></br>
                    <p>
                        Decidimos então fazer um balcão horizontal e grande, com mobiliário funcional para a operação do bar.
                    </p><br></br>
                    <p>
                        O mobiliário foi pensado para incentivar o contato e a conversa entre os clientes, o que mais tarde os leva para o ambiente da balada, mantendo os clientes dia e a noite no espaço.
                    </p><br></br>
                    <p>
                        O projeto do Melanina foi desenvolvido antes mesmo do nome e da identidade visual do bar.
                    
                        Dessa forma, o que norteou todo o branding da marca foi o projeto arquitetônico. Para que a marca saísse em sintonia com o projeto, o studio participou das reuniões de design e ajudou a definir os conceitos, criamos a marca juntos!
                    </p><br></br>
                    <p>
                    As plantas que aparecem nas fotos do bar, foram um presente nosso para eles. Quando o bar inaugurou, elas estavam bem pequenininhas e agora estão enormes e lindas!
                    </p>
                    </div>
                </div>

                 {/* ultima linha */}

                 <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2}`}>
                        <img className={styles.imgGaleria} src={image15} alt=""></img>
                    </div>

                    <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}`}>
                        <img className={styles.imgGaleria} src={image16} alt=""></img>
                    </div>

            </div>
        </div>
    )
}

export default Melanina;






