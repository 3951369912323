import title1 from "../pedras_interior/interior_titles/pedras_interior2.svg"
import styles from "./styles.module.css"
import GoToTop from "../../0components/GoToTop/GoToTop"
import image1 from "../../2assets/pedras_interior/pedras_interior1.jpg"
import image2 from "../../2assets/pedras_interior/pedras_interior2.jpg"
import image3 from "../../2assets/pedras_interior/pedras_interior3.jpg"
import image4 from "../../2assets/pedras_interior/pedras_interior4.jpg"
// import image5 from "../../2assets/pedras_interior/pedras_interior5.jpg"
import image6 from "../../2assets/pedras_interior/pedras_interior6.jpg"
import image7 from "../../2assets/pedras_interior/pedras_interior7.jpg"
import image8 from "../../2assets/pedras_interior/pedras_interior8.jpg"
import image9 from "../../2assets/pedras_interior/pedras_interior9.jpg"
import image10 from "../../2assets/pedras_interior/pedras_interior10.jpg"
import image11 from "../../2assets/pedras_interior/pedras_interior11.jpg"
import image12 from "../../2assets/pedras_interior/pedras_interior12.jpg"
import image13 from "../../2assets/pedras_interior/pedras_interior13.jpg"
import image14 from "../../2assets/pedras_interior/pedras_interior14.jpg"
import image15 from "../../2assets/pedras_interior/pedras_interior15.jpg"
import image16 from "../../2assets/pedras_interior/pedras_interior16.jpg"
import image17 from "../../2assets/pedras_interior/pedras_interior17.jpg"
import image18 from "../../2assets/pedras_interior/pedras_interior18.jpg"
import image19 from "../../2assets/pedras_interior/pedras_interior19.jpg"
import image20 from "../../2assets/pedras_interior/pedras_interior20.jpg"
import image21 from "../../2assets/pedras_interior/pedras_interior21.jpg"
import image22 from "../../2assets/pedras_interior/pedras_interior22.jpg"
import image23 from "../../2assets/pedras_interior/pedras_interior23.jpg"
// import image24 from "../../2assets/pedras_interior/pedras_interior24.jpg"
import image25 from "../../2assets/pedras_interior/pedras_interior25.jpg"
import image26 from "../../2assets/pedras_interior/pedras_interior26.jpg"
import image27 from "../../2assets/pedras_interior/pedras_interior27.jpg"
import image28 from "../../2assets/pedras_interior/pedras_interior28.jpg"
import image29 from "../../2assets/pedras_interior/pedras_interior29.jpg"
import image30 from "../../2assets/pedras_interior/pedras_interior30.jpg"
import image31 from "../../2assets/pedras_interior/pedras_interior31.jpg"
import image32 from "../../2assets/pedras_interior/pedras_interior32.jpg"
// import image33 from "../../2assets/pedras_interior/pedras_interior33.jpg"
import image34 from "../../2assets/pedras_interior/pedras_interior34.jpg"
import image35 from "../../2assets/pedras_interior/pedras_interior35.jpg"
import image36 from "../../2assets/pedras_interior/pedras_interior36.jpg"
import image37 from "../../2assets/pedras_interior/pedras_interior37.jpg"
import image38 from "../../2assets/pedras_interior/pedras_interior38.jpg"
import image39 from "../../2assets/pedras_interior/pedras_interior39.jpg"
import image40 from "../../2assets/pedras_interior/pedras_interior40.jpg"
import image41 from "../../2assets/pedras_interior/pedras_interior41.jpg"
import image42 from "../../2assets/pedras_interior/pedras_interior42.jpg"
import image43 from "../../2assets/pedras_interior/pedras_interior43.jpg"
// import image44 from "../../2assets/pedras_interior/pedras_interior44.jpg"
import image45 from "../../2assets/pedras_interior/pedras_interior45.jpg"
import image46 from "../../2assets/pedras_interior/pedras_interior46.jpg"
import image47 from "../../2assets/pedras_interior/pedras_interior47.jpg"
import image48 from "../../2assets/pedras_interior/pedras_interior48.jpg"
import image49 from "../../2assets/pedras_interior/pedras_interior49.jpg"
import image50 from "../../2assets/pedras_interior/pedras_interior50.jpg"
import image51 from "../../2assets/pedras_interior/pedras_interior51.jpg"
import image52 from "../../2assets/pedras_interior/pedras_interior52.jpg"
import image53 from "../../2assets/pedras_interior/pedras_interior53.jpg"
import image54 from "../../2assets/pedras_interior/pedras_interior54.jpg"
import image55 from "../../2assets/pedras_interior/pedras_interior55.jpg"
import image56 from "../../2assets/pedras_interior/pedras_interior56.jpg"
import image57 from "../../2assets/pedras_interior/pedras_interior57.jpg"
import image58 from "../../2assets/pedras_interior/pedras_interior58.jpg"
// import image59 from "../../2assets/pedras_interior/pedras_interior59.jpg"
// import image60 from "../../2assets/pedras_interior/pedras_interior60.jpg"
import image61 from "../../2assets/pedras_interior/pedras_interior61.jpg"
import image62 from "../../2assets/pedras_interior/pedras_interior62.jpg"
// import image63 from "../../2assets/pedras_interior/pedras_interior63.jpg"
// import image64 from "../../2assets/pedras_interior/pedras_interior64.jpg"
import image65 from "../../2assets/pedras_interior/pedras_interior65.jpg"
import image66 from "../../2assets/pedras_interior/pedras_interior66.jpg"
import image67 from "../../2assets/pedras_interior/pedras_interior67.jpg"
import image68 from "../../2assets/pedras_interior/pedras_interior68.jpg"
import image69 from "../../2assets/pedras_interior/pedras_interior69.jpg"
import image70 from "../../2assets/pedras_interior/pedras_interior70.jpg"
import image71 from "../../2assets/pedras_interior/pedras_interior71.jpg"
// import image72 from "../../2assets/pedras_interior/pedras_interior72.jpg"
import image73 from "../../2assets/pedras_interior/pedras_interior73.jpg"
import image74 from "../../2assets/pedras_interior/pedras_interior74.jpg"
// import image75 from "../../2assets/pedras_interior/pedras_interior75.jpg"
import image76 from "../../2assets/pedras_interior/pedras_interior76.jpg"
import image77 from "../../2assets/pedras_interior/pedras_interior77.jpg"
import image78 from "../../2assets/pedras_interior/pedras_interior78.jpg"
// import image79 from "../../2assets/pedras_interior/pedras_interior79.jpg"
import image80 from "../../2assets/pedras_interior/pedras_interior80.jpg"
import image81 from "../../2assets/pedras_interior/pedras_interior81.jpg"
// import image82 from "../../2assets/pedras_interior/pedras_interior82.jpg"
// import image83 from "../../2assets/pedras_interior/pedras_interior83.jpg"
import image84 from "../../2assets/pedras_interior/pedras_interior84.jpg"
import image85 from "../../2assets/pedras_interior/pedras_interior85.jpg"
import image86 from "../../2assets/pedras_interior/pedras_interior86.jpg"
// import image87 from "../../2assets/pedras_interior/pedras_interior87.jpg"





function Index() {


    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image74} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h3} ${styles.w2} ${styles.textBlock}`}>
                    <img className={styles.imgGaleriaTitle} src={title1} alt=""></img>
                    <div className={styles.textBox}>
                        <p>
                            Quando começamos a desenvolver o projeto arquitetônico dessa casa, o interior dela não saia da nossa cabeça. O projeto de interiores deveria remeter e dar continuidade à estética natural e de conforto da casa.
                        </p><br></br>
                        <p>
                            Em contraste com o concreto e o cimento queimado usado nas paredes e nos pisos, fizemos uso da madeira em tons escuros para compor o mobiliário dessa casa, que foi pensado de forma a integrar e acolher quem ali estivesse.
                        </p><br></br>
                        <p>
                            O uso de iluminação indireta e amarelada também tem papel importante no resultado final de acolhimento e relaxamento, transformando a casa em um verdadeiro refúgio. Em todos os ambientes temos sancas para a iluminação.
                        </p>
                        <p>
                            O volume social prepara para o que veremos na área íntima da casa: ambientes tranquilos, com pouca informação, funcionais e bem arejados, com muita iluminação natural.
                        </p><br></br>
                        <p>
                            Para garagem: aproveitamos o caimento natural do terreno e construímos a garagem no subsolo da casa, esse espaço também irá abrigar uma pequena oficina.
                        </p><br></br>
                    </div>
                </div>


                {/* ENTRADA E SALA DE ESTAR COM COZINHA */}

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image73} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image76} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image78} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image77} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image80} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.iw4} ${styles.ih3}`}>
                    <img className={styles.imgGaleria} src={image81} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image71} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.ih1} ${styles.iw2}`}>
                    <img className={styles.imgGaleria} src={image84} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image85} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} ${styles.iw4} `}>
                    <img className={styles.imgGaleria} src={image86} alt="" ></img>
                </div>




                {/* QUARTO PRINCIPAL E BANHEIRO */}

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image30} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image45} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image46} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image32} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image40} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image29} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih3}`}>
                    <img className={styles.imgGaleria} src={image41} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image42} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image43} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3}  ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image31} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}  `}>
                    <img className={styles.imgGaleria} src={image28} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw4} ${styles.ih2}`}>
                    <img className={styles.imgGaleria} src={image27} alt=""></img>
                </div>  

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image26} alt="" ></img>
                </div> 

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image25} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image34} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image35} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image36} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image37} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image38} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.ih2} `}>
                    <img className={styles.imgGaleria} src={image39} alt=""></img>
                </div>



                {/* ESCRITÓRIO LINDO */}

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} `}>
                    <img className={styles.imgGaleria} src={image15} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image16} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image17} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image18} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image19} alt=""></img>
                </div>


                {/* ACADEMIA SUBSOLO */}

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw4} ${styles.ih2}`}>
                    <img className={styles.imgGaleria} src={image20} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.iw4} ${styles.ih3}`}>
                    <img className={styles.imgGaleria} src={image21} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image22} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image23} alt="" ></img>
                </div>


                {/* EXTERNA E PISCINA */}

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image3} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image2} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw4}`}>
                    <img className={styles.imgGaleria} src={image4} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image6} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image7} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h3} ${styles.w4}  `}>
                    <img className={styles.imgGaleria} src={image8} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image9} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image10} alt=""></img>
                </div>


                {/* BANHEIRO E SAUNA EXTERNA  */}

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw4} ${styles.ih3}`}>
                    <img className={styles.imgGaleria} src={image61} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w3} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image62} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image65} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image66} alt="" ></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image67} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image1} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image11} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image68} alt=""></img>
                </div>
                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image69} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image70} alt=""></img>
                </div>


                {/* QUARTOS DE HÓSPEDES */}

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} ${styles.iw2} ${styles.ih1}`}>
                    <img className={styles.imgGaleria} src={image47} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}`}>
                    <img className={styles.imgGaleria} src={image49} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image50} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w4}  `}>
                    <img className={styles.imgGaleria} src={image48} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image51} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image52} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image53} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image54} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image55} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image56} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image57} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image58} alt=""></img>
                </div>


                {/* AREA DE SERVIÇOS */}

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2}  `}>
                    <img className={styles.imgGaleria} src={image12} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h2} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image13} alt=""></img>
                </div>

                <div className={`${styles.itemGaleria} ${styles.h1} ${styles.w2} `}>
                    <img className={styles.imgGaleria} src={image14} alt=""></img>
                </div>

            </div>
            <GoToTop />
        </div>
    )
}

export default Index;



